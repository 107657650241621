import './App.css';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BrowserRouter, Routes, Route, NavLink, Link, useLocation } from "react-router-dom";

// window.apiRoot = 'https://demo-fintech.jcms.ru/api';
window.apiRoot = 'http://jcms2/api';
var keyPage = '';

function PageHome() {
  return (
    <Row className="main_row content Carousel-background">
    <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

    <Col lg={8} xl={8} className="w-100 h-auto p-3">
    <Carousel>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1>Более <span>2000</span> компаний доверяют нам</h1>
        <h2>Компании всех размеров полагаются на нас для цифровой трансформации своих процессов управления кредитами, сборами и дебиторской задолженностью. Благодаря нам они могут быстро масштабировать свои процессы, сохраняя при этом точность и эффективно реагируя на рост объема и сложности транзакций.</h2>
        <Link to="/company"><button>Подробнее о нас</button></Link>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1><span>Индивидуальные</span> цены</h1>
        <h2>Повысьте производительность, ускорьте время обработки и поднимите свои кредиты, коллекции и дополненную реальность на новый уровень на одной <span>адаптируемой автоматизированной платформе</span>.</h2>
        <Link to="/pricing" className="nav-link"><button>Подробнее о ценах</button></Link>
      </Carousel.Caption>
    </Carousel.Item>

    <Carousel.Item>
      <Carousel.Caption className="d-flex flex-column h-100 align-items-center justify-content-center">
        <h1>Корпоративное решение</h1>
        <h2>Наша облачная платформа <span>B2B</span> ускоряет обработку транзакций за счет оцифровки и автоматизации данных, задач и процессов. Наши клиенты обычно достигают повышения производительности более чем на 60% в течение первых трех месяцев внедрения.</h2>
        <Link to="/solutions" className="nav-link"><button>Подробнее о платформе</button></Link>
      </Carousel.Caption>
    </Carousel.Item>
    </Carousel>
    </Col>

    <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
    </Row>
  );
}

function PageNotFound() {
  return (
    <div>Страница не найдена!</div>
  );
}

function PageView() {
  const location = useLocation();
  const [title, setTitle] = useState(null);
  const [text, setText] = useState(null);
  
  const [block1, setBlock1] = useState(null);
  const [block2, setBlock2] = useState(null);

  const [loading, setLoading] = useState(true);

  const [news, setNews] = useState(false);
  const [customers, setCustomers] = useState(false);
  const [solutions, setSolutions] = useState(false);
  const [pricing, setPricing] = useState(false);
  const [company, setСompany] = useState(false);
  const [contacts, setContacts] = useState(false);

  useEffect(() => {
    const apiUrl = `${window.apiRoot}${location.pathname}`;
    
    const requestOptions = {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    };

    setNews(false);
    setCustomers(false);
    setSolutions(false);
    setPricing(false);
    setСompany(false);
    setContacts(false);
    setLoading(true);

    if(keyPage != location.key){
      fetch(apiUrl, requestOptions)
      .then(response => {
        setLoading(false);
        return response.json();
      }).then(data => {
        

        if(location.pathname == "/pricing"){setPricing(true);}
        if(location.pathname == "/company"){
          setСompany(true);

          setBlock1(data.block1);
          setBlock2(data.block2);
        }

        setTitle(data.title);
        setText(data.text);
      }).catch((e: Error) => {
        console.log(e.message);
      });
    }

    keyPage = location.key;

  }, [location]);

  return (
    <>
      <Row className="main_row content">
      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

      {loading?
        <Col lg={8} xl={8} className="d-flex justify-content-center align-items-center w-100 h-100">
        <div className="App-loading"></div>
        </Col>
        :
        <>
          <Col lg={8} xl={8} className="p-3 title center">
          <h1>{title}</h1>
          <p>{text}</p>
          </Col>
        </>
      }

      <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
      </Row>

      {pricing?
        <>
          <Row className="main_row pricing">
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          <Col lg={8} xl={8} className="p-3">
            Какая-то дичь про цены
          </Col>
          <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

    {company?
        <>
          <Row className="main_row about">
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col lg={8} xl={8} className="p-3"><h2>Наша история</h2></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>

            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
            <Col xxl={4} xl={4} lg={4} className="p-5 dark_block" dangerouslySetInnerHTML={{__html: block1}}></Col>
            <Col xxl={4} xl={4} lg={4} className="p-5 gray_block" dangerouslySetInnerHTML={{__html: block2}}></Col>
            <Col xxl={2} xl={2} lg={2} className="d-none d-sm-none d-md-none d-lg-block d-xl-block"></Col>
          </Row>
        </>
      :""}

    </>
  );
}

function App() {
  return (
      <div className="App-main">
<BrowserRouter>  
  <Row className="App-navbar header main_row w-100">
    <Col lg={2} xl={2}></Col>
    <Col lg={8} xl={8}>
      <Navbar expand="lg">
        <Navbar.Brand><Link to="/"><div className="fintech-logo"></div></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Item><NavLink as={NavLink} to="/news" className="nav-link">Новости</NavLink></Nav.Item>
          <Nav.Item><NavLink as={NavLink} to="/customers" className="nav-link">Клиенты</NavLink></Nav.Item>
          <Nav.Item><NavLink as={NavLink} to="/solutions" className="nav-link">Решения</NavLink></Nav.Item>
          <Nav.Item><NavLink as={NavLink} to="/pricing" className="nav-link">Цены</NavLink></Nav.Item>
          <Nav.Item><NavLink as={NavLink} to="/company" className="nav-link">Компания</NavLink></Nav.Item>
          <Nav.Item><NavLink as={NavLink} to="/contacts" className="nav-link">Контакты</NavLink></Nav.Item>

          {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">
              Separated link
            </NavDropdown.Item>
          </NavDropdown> */}
        </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Col>
    <Col lg={2} xl={2}></Col>
  </Row>


      <Routes>
        <Route index element={<PageHome />} />
        <Route path="/news" element={<PageView />} />
        <Route path="/customers" element={<PageView />} />
        <Route path="/solutions" element={<PageView />} />
        <Route path="/pricing" element={<PageView />} />
        <Route path="/company" element={<PageView />} />
        <Route path="/contacts" element={<PageView />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>

  

  <Row className="App-navbar main_row footer">
  <Col xl={2}></Col>
  <Col xl={6}><Navbar expand="lg" className="App-footer">© IT-компания JAS 2024. Сделано с помощью модульной системы управления JCMS. Данный сайт является демонстрационной версией.<br />Вся информация на сайте несёт исключительно демонстрационный характер.</Navbar></Col>
  <Col xl={2} className="logos text-center p-3">
  <a href="https://www.jas.ru"><div className="jas-logo d-inline-block m-3"></div></a>
  <a href="https://www.jcms.ru"><div className="jcms-logo d-inline-block m-3"></div></a>
  </Col>
  <Col xl={2}></Col>
  </Row>
</BrowserRouter>
      </div>
  );
}

export default App;
